import "../../../assets/css/custom.css";
import "../../../assets/css/dashboard-style.css";
import "../../../assets/css/hover.css";
import React, { useState } from 'react';
import DashboardBanner from "./components/dashboardBanner";
import DashboardHeading from "./components/dashboardHeading";
import DashboardSummary from "./components/dashboardSummery";
import SmartContractModal from "../../../components/modal/smartContractModal";
import NFTGraph from "./components/nftGraph";
import DashboardOrderHistory from "./components/dashboardOrderHistory";
import Tabs from "../../../components/common/tabs";
import { TAB } from "../../constants/constants";
import DashboardDocument from "./components/dashboardDocument";

const Dashboard = () => {
  const [page, setPage] = useState(TAB.GREENH2)
  const [isDashboardLoading, setIsDashboardLoading] = useState(false);

  return (
    <div className="energy-nft-items m-4 m-lg-5">
      <Tabs setPage={setPage} />
      {
        page === TAB.GREENH2 &&
        <div className="card border-0">
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="energy-nft-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
              <DashboardBanner />
              <div className="service-content">
                <DashboardHeading tabs="no-tab" tabSwitcher="no" />
                <DashboardSummary isDashboardLoading={isDashboardLoading} setIsDashboardLoading={setIsDashboardLoading} />
                <SmartContractModal />
                <DashboardDocument />
                <NFTGraph isDashboardLoading={isDashboardLoading} />
              </div>
              <DashboardOrderHistory isDashboardLoading={isDashboardLoading} />
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Dashboard