export const kycConstants = {
  SET_START_KYC: "SET_START_KYC",
  SET_SUMSUB_ACCESS_TOKEN: "SET_SUMSUB_ACCESS_TOKEN",
  SET_KYC_STATUS: "SET_KYC_STATUS"
}

const setStartKYC = (status) => {
  return {
    type: kycConstants.SET_START_KYC,
    payload: {
      status,
    },
  };
};

const setSumSubAccessToken = (sumSubAccessToken) => {
  return {
    type: kycConstants.SET_SUMSUB_ACCESS_TOKEN,
    payload: {
      sumSubAccessToken,
    },
  };
};

const checkUserKYCStatus = (callback) => {
  return async (dispatch) => {
    const result = await callback();
    if (result) {
      dispatch({
        type: kycConstants.SET_KYC_STATUS,
        payload: {
          isUserKYCDone: result.is_kyc_done,
          userKYCStatus: result.kyc_status,
          rejectedType: result.review_reject_type,
        },
      });
    } else {
      dispatch({
        type: kycConstants.SET_KYC_STATUS,
        payload: {
          userKYCStatus: "",
          isUserKYCDone: false,
          rejectedType: "",
        },
      });
    }
  };
};

export {
  setStartKYC,
  setSumSubAccessToken,
  checkUserKYCStatus
};