/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";
import Header from "../layout/header";
import SideBar from "../layout/side-bar";
import PrivateFooter from "../layout/footer/private-footer";

const ThemeContainer = ({ children }) => {

  return (
    <Fragment>
      <Header />
      <div className="main-content">
        <SideBar />
        <div className="page-content float-start position-relative">
          <div className="content-wrapper service-nft">
            {children}
          </div>
        </div>
      </div>
      <PrivateFooter />
    </Fragment>
  );
};

export default ThemeContainer;
