import React from "react";

const GraphTableTabs = () => {
  return (
    <ul className="nav nav-pills" id="pills-tab" role="tablist">
      <li className="nav-item" role="presentation">
        <button
          className="nav-link active"
          id="pills-home-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-home"
          type="button"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
        >
          <i className="fa fa-line-chart me-2" aria-hidden="true"></i>Graphical
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className="nav-link "
          id="tabular-profile-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-profile"
          type="button"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
        >
          <i className="fa fa-table me-2" aria-hidden="true"></i>Tabular
        </button>
      </li>
    </ul>
  );
};

export default GraphTableTabs;
