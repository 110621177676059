import "./App.css";
import "../assets/css/project-details.css";
import { Suspense } from 'react';
import RoutesPage from './routes';
import { BrowserRouter } from 'react-router-dom';
import ThemeContainer from "../components/containers/theme-container";
import BootstrapToast from "../components/toast";

function App({ basename }) {
  return (
    <BrowserRouter basename={basename}>
      <BootstrapToast />
      <Suspense>
        <ThemeContainer>
          <RoutesPage />
        </ThemeContainer>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
