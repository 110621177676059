/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dashboardAPI, userDashboardAPI, getInvestmentDetail } from "../../nft/services";
import { setDashboardData, setUserDashboardData } from "../../nft/redux/nftAction";
import { historyResponseSet } from "../../../utils/token";

const DashboardSummary = ({ isDashboardLoading, setIsDashboardLoading }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const [myToken, setMyToken] = useState(0);
  const { selectedCrypto, greenH2SelectedCrypto, userDashboard, masterData } = useSelector((state) => state.nft);
  const { currentPage } = useSelector((state) => state.pagination);

  const data = {
    token_id: selectedCrypto,
  };

  const getData = async () => {
    if (selectedCrypto) {
      const res = await dashboardAPI(data);
      if (res.status) {
        setValue(res.dashboard_data);
        const val = {
          dashboard: res.dashboard_data,
          offSet: res.utc_offset,
        };
        if (userDashboard?.dashboard_data.snt_tokens !== res.dashboard_data.snt_tokens) {
          dispatch(setDashboardData(val));
        }
      }
    }
  };

  const userData = async () => {
    if (selectedCrypto) {
      const res = await userDashboardAPI(data);
      if (res.status) {
        const getToken = await getInvestmentDetail();
        if (getToken) {
          const investmentProjectWise = await historyResponseSet(getToken?.investment);
          const token = investmentProjectWise?.length ? investmentProjectWise.filter((ele) => ele?.project_id === selectedCrypto) : 0
          setMyToken(token[0]?.purchased_token);
        }
        dispatch(setUserDashboardData(res));
      }
    }

    setIsDashboardLoading(false);
  };

  useEffect(() => {
    setIsDashboardLoading(true);
    getData();
    userData();
  }, [selectedCrypto, greenH2SelectedCrypto, currentPage]);

  return (
    <div className="row counter-items mb-4 mb-lg-5">
      <div className="col-12 col-md-6 col-xxl-3 counter-item mb-4">
        {isDashboardLoading ?
          <div className="summery-data-loader">
          </div> :
          <div className="counter-item-content">
            <h3 style={{ color: "black", fontSize: "24px" }}>{value?.snt_market_cap.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</h3>
            <p className="mb-0">Market Cap</p>
          </div>
        }
      </div>
      <div className="col-12 col-md-6 col-xxl-3 counter-item mb-4">
        {isDashboardLoading ?
          <div className="summery-data-loader">
          </div> :
          <div className="counter-item-content">
            <h3 style={{ color: "black", fontSize: "24px" }}>{value?.snt_total_circulating_supply.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</h3>
            <p className="mb-0">Circulating Supply</p>
          </div>
        }
      </div>
      <div className="col-12 col-md-6 col-xxl-2 counter-item mb-4">
        {isDashboardLoading ?
          <div className="summery-data-loader">
          </div> :
          <div className="counter-item-content">
            <h3 style={{ color: "black", fontSize: "24px" }}>{masterData?.crypto_tokens?.filter((ele) => ele.id === selectedCrypto)[0]?.locking_period ? new Date(masterData?.crypto_tokens?.filter((ele) => ele.id === selectedCrypto)[0]?.locking_period).toLocaleDateString() : "dd-mm-yyyy"}</h3>
            <p className="mb-0">Locking Period</p>
          </div>
        }
      </div>
      <div className="col-12 col-md-6 col-xxl-2 counter-item mb-4">
        {isDashboardLoading ?
          <div className="summery-data-loader">
          </div> :
          <div className="counter-item-content">
            <h3 style={{ color: "black", fontSize: "24px" }}>${value?.snt_price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</h3>
            <p className="mb-0">Price</p>
          </div>
        }
      </div>
      <div className="col-12 col-md-6 col-xxl-2 counter-item mb-4">
        {isDashboardLoading ?
          <div className="summery-data-loader">
          </div> :
          <div className="counter-item-content">
            <h3 style={{ color: "black", fontSize: "24px" }}>{myToken ? myToken.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 }) : 0}</h3>
            <p className="mb-0">My Token</p>
          </div>
        }
      </div>
    </div>
  );
};

export default DashboardSummary;
