/* eslint-disable no-negated-condition */
import axios from "axios";
import api_end_point from "../../../constants/api_end_point";

const updateBankDetail = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.WALLET_BASE_URL}${api_end_point.UPDATE_BANK_DETAILS}`,
    data: values
  });
  return response?.data;
};

export {
  updateBankDetail,
}