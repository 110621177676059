export const paginationConstant = {
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  SET_PAGE_NO: "SET_PAGE_NO",
}

const setCurrentPage = (values) => {
  return {
    type: paginationConstant.SET_CURRENT_PAGE,
    payload: {
      currentPage: values,
    },
  };
};

const setPageNumber = (values) => {
  return {
    type: paginationConstant.SET_PAGE_NO,
    payload: {
      pageNumber: values,
    },
  };
};

export {
  setCurrentPage,
  setPageNumber,
}