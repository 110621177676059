import React from "react";
import { useSelector } from "react-redux";
import MonthlyInvestment from "../../../../components/modal/monthlyInvestment";

const DashboardOrderHistory = ({ isDashboardLoading }) => {
  const { userDetail } = useSelector((state) => state.home);
  const { userDashboard } = useSelector((state) => state.nft);

  const cashDecimal = userDetail?.cash_decimal_limit;
  const cryptoDecimal = userDetail?.crypto_decimal_limit;

  return (
    <>
      <div className="orderhistory-head mx-0 mb-2 mb-md-1 mt-4 mt-md-1 text-left">
        <h1 style={{ marginLeft: "70px" }}>Order History</h1>
      </div>
      <div className="order-history mx-0 my-3 p-4">
        {userDashboard && userDashboard.dashboard_data ? (
          isDashboardLoading ?
            <div className="order-history-loader my-4 mx-1 mx-md-5"></div>
            :
            <div className="dasboard-numbers my-4 mx-1 mx-md-5">
              <div className="row p-3">
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 my-3 my-md-0">
                  <div className="number-inner text-center">
                    <h2 className="order-head">
                      {userDashboard?.dashboard_data?.snt_tokens.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: cryptoDecimal,
                        }
                      )}
                    </h2>
                    <p className="order-text m-0">Tokens</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 my-3 my-md-0">
                  <div className="number-inner text-center">
                    <h2 className="order-head">
                      ${" "}
                      {userDashboard?.dashboard_data?.total_investments_usd.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: cashDecimal,
                          maximumFractionDigits: cashDecimal,
                        }
                      )}
                    </h2>
                    <p className="order-text m-0">Total Investments</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 my-3 my-md-0">
                  <div className="number-inner text-center">
                    <h2 className="order-head">
                      ${" "}
                      {userDashboard?.dashboard_data?.current_valuation.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: cashDecimal,
                          maximumFractionDigits: cashDecimal,
                        }
                      )}
                    </h2>
                    <p className="order-text m-0">Current Valuation</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 my-3 my-md-0">
                  <div className="number-inner text-center">
                    <h2 className="order-head-pl">
                      ${" "}
                      {userDashboard?.dashboard_data?.profit_or_loss.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: cashDecimal,
                          maximumFractionDigits: cashDecimal,
                        }
                      )}
                    </h2>
                    <p className="order-text-pl m-0">Profit/Loss </p>
                  </div>
                </div>
              </div>
            </div>
        ) : (
          ""
        )}
        {userDashboard?.investment_by_month && (
          <div className="orderhistory-graph my-4 mx-1 mx-md-5">
            <div className="pt-4  ps-4">
              <h4 className="font-656565">Monthly Investment</h4>
            </div>
            {
              isDashboardLoading ?
                <div className="monthly-graph-loader"></div>
                :
                <div className="p-4">
                  <MonthlyInvestment data={userDashboard?.investment_by_month} />
                </div>
            }
          </div>
        )}
        {userDashboard?.token_by_month && (
          <div className="orderhistory-graph my-4 mx-1 mx-md-5">
            <div className="pt-4  ps-4">
              <h4 className="font-656565">Monthly Token Bought</h4>
            </div>
            {
              isDashboardLoading ?
                <div className="monthly-graph-loader"></div>
                :
                <div className="p-4">
                  <MonthlyInvestment data={userDashboard?.token_by_month} />
                </div>
            }
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardOrderHistory;
