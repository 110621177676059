/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCrypto } from "../../nft/redux/nftAction";

const OtherNFTProjects = ({ image }) => {
  const dispatch = useDispatch();
  const { masterData, selectedCrypto } = useSelector((state) => state.nft);
  const data = masterData;

  const handleChange = (e) => {
    const num = parseInt(e.target.value, 10);
    dispatch(setSelectedCrypto(num));
  };

  useEffect(() => {
    dispatch(setSelectedCrypto(selectedCrypto ? selectedCrypto : data?.crypto_tokens[0]?.id ? data?.crypto_tokens[0]?.id : 0));
  }, []);

  return (
    <>
      {data !== null && (
        <select className="form-select mb-3 w-auto" aria-label="Large select example" value={selectedCrypto} onChange={handleChange}>
          {data.crypto_tokens &&
            data.crypto_tokens.map((val, index) => (
              <option value={val.id} key={index}>{val.token_code}</option>
            ))
          }
        </select>
      )}
    </>
  );
};

export default OtherNFTProjects;
