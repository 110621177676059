/* eslint-disable no-unused-vars */
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from "axios";
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Inject  axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App basename={PUBLIC_URL} />
      </PersistGate>
    </Provider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
