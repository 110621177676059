import "../../../assets/css/dashboard-style.css";
import "../../../assets/css/hover.css";
import React, { useEffect, useState } from "react";
import Tabs from "../../../components/common/tabs";
import NFTTabDashboard from "./components/nftTabContentDashboard";
import { TAB } from "../../constants/constants";

const GreenH2Nft = () => {
  const [page, setPage] = useState(TAB.GREENH2)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="energy-nft-items m-4 m-lg-5">
      <Tabs setPage={setPage} />
      <div className="tab-content" id="myTabContent">
        {
          page === TAB.GREENH2 &&
          <NFTTabDashboard enegrynft="no" page={"greenH2"} />
        }
      </div>
    </div>
  );
};

export default GreenH2Nft;
