import { getQueryParams, handleLogout } from "../../app/utils/token";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      config.headers.auth_key = "C9941611DF64B";
      config.headers["content-type"] = "text/plain";

      if (config?.method === "Post" || config?.method === "post" || config?.method === "POST") {
        const state = store.getState()
        const userDetail = state?.home?.userDetail;
        const userId = userDetail?.user_id;
        const accessToken = userDetail?.access_token;
        const routeData = getQueryParams();

        if (!config?.data?.access_token && accessToken !== null) {
          if (config?.data) {
            config.data = { user_id: parseInt(userId, 10), access_token: accessToken, ...config.data }
          } else {
            config.data = { user_id: parseInt(userId, 10), access_token: accessToken }
          }
        } else if (!config?.data?.access_token && routeData?.access_token) {
          if (config?.data) {
            config.data = { user_id: parseInt(routeData?.user_id, 10), access_token: routeData?.access_token, ...config.data }
          } else {
            config.data = { user_id: parseInt(routeData?.user_id, 10), access_token: routeData?.access_token }
          }
        }
      }
      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(
    response => {
      return response
    },
    err => {
      if (err.response && err.response.status === 404) {
        const state = store.getState()
        const userDetail = state?.home?.userDetail;
        handleLogout({ natureUserId: userDetail?.nature_user_id, natureAccessToken: userDetail?.nature_access_token });
      }
      Promise.reject(err)
    }
  );
}
