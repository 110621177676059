/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { getQueryParams, loginRedirection } from "../utils/token";
import { setIsMetamaskInstalled, setIsWalletConnected, setUserLoginStatus, setUserWalletAddress } from "../pages/home/redux/homeAction";
import metamaskService from "../pages/smartContracts/metamask";
import SubsubKYC from "../pages/kyc/sumsubKyc";
import { dashboardAPI, getMasterData } from "../pages/nft/services";
import { setDashboardData, setMasterData, setSelectedCrypto } from "../pages/nft/redux/nftAction";
import { checkUserKYCStatus } from "../pages/kyc/redux/kycAction";
import { getUserKYCStatus } from "../pages/kyc/services";
import { checkLogin } from "./checkLogin";
import { setUserDetail } from "../pages/home/redux/homeAction";

const PrivateRoute = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isMetamaskInstalled, userDetail } = useSelector((state) => state.home);
  const { dashboard, masterData, selectedCrypto } = useSelector((state) => state.nft);
  const { startKYC, isUserKYCDone } = useSelector((state) => state.kyc);

  const [isAuthenticate, setIsAuthenticate] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const LocalStorageWalletAddress = userDetail?.wallet_address;
    fetchData();
    setTimeout(() => {
      !isMetamaskInstalled && fetchMetamaskInstallation();
      (isMetamaskInstalled || LocalStorageWalletAddress === "") && fetchUserAddress();
      (masterData === null) && fetchMasterData();
      (dashboard === null) && fetchDashboard();
      setIsLoading(false);
    }, 3000);
  }, [location]);

  const fetchData = async () => {
    try {
      const userId = userDetail?.nature_user_id;
      const accessToken = userDetail?.nature_access_token;
      const routeData = getQueryParams();

      if (routeData?.user_id && routeData?.access_token) {
        const checkWithRoute = await checkIsLogin(routeData?.user_id, routeData?.access_token);
        if (!checkWithRoute) {
          const checkWithStorage = await checkIsLogin(parseInt(userId), accessToken);
          if (!checkWithStorage) {
            loginRedirection();
          }
        }
      } else if (userId && accessToken) {
        const checkWithStorage = await checkIsLogin(parseInt(userId), accessToken);
        if (!checkWithStorage) {
          loginRedirection();
        }
      } else {
        setIsAuthenticate(false);
        dispatch(setUserLoginStatus(false));
        loginRedirection();
      }
    } catch (error) {
      console.log("privateRoute : ", error);
    }
  };

  const checkIsLogin = async (userId, accessToken) => {
    const result = await checkLogin({ user_id: parseInt(userId), access_token: accessToken });
    if (result?.status) {
      dispatch(setUserDetail(result?.loginUserDetail));
      !isUserKYCDone && dispatch(checkUserKYCStatus(getUserKYCStatus));
      dispatch(setUserLoginStatus(true));
      setIsAuthenticate(true);
      if (!result?.loginUserDetail?.kyc_done) {
        window.location.replace(`${process.env.REACT_APP_KYC_VERIFICATION_URL}?user_id=${userId}&access_token=${accessToken}`);
      }

      if (result?.loginUserDetail?.isAdmin) {
        window.location.replace(`${process.env.REACT_APP_ADMIN_NATURE_URL}?user_id=${userId}&access_token=${accessToken}`);
      }

      return true;
    } else {
      dispatch(setUserDetail(result?.loginUserDetail));
      return false;
    }
  }

  const fetchMetamaskInstallation = async () => {
    try {
      const result = await metamaskService.checkMetamaskInstallation();
      if (result) {
        dispatch(setIsMetamaskInstalled(result));
        await fetchUserAddress();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserAddress = async () => {
    const walletAddress = await userDetail?.wallet_address;
    if (walletAddress !== "") {
      dispatch(setUserWalletAddress(walletAddress));
      const result = await metamaskService.getUserAddress();
      if (result && !result.error && result?.account === walletAddress) {
        dispatch(setIsWalletConnected(true));
      } else {
        dispatch(setIsWalletConnected(false));
      }
    }
    // if (walletAddress === "") {
    //   const result = await metamaskService.getUserAddress();
    //   if (result && !result.error) {
    //     setWalletAddress(result.account);
    //     dispatch(setUserWalletAddress(result.account));
    //     dispatch(setIsWalletConnected(true));
    //     const data = {
    //       walletAddress: result.account,
    //     };
    //     await updateWalletAddress(data);
    //   } else {
    //     dispatch(setUserWalletAddress(null));
    //     dispatch(setIsWalletConnected(false));
    //   }
    // }
  }

  const fetchDashboard = async () => {
    if (selectedCrypto) {
      const data = {
        token_id: selectedCrypto,
      };
      const res = await dashboardAPI(data);
      if (res.status) {
        const value = {
          dashboard: res.dashboard_data,
          offSet: res.utc_offset,
        };
        dispatch(setDashboardData(value));
      }
    }
  };

  const fetchMasterData = async () => {
    const res = await getMasterData();
    if (res?.status === true) {
      dispatch(setSelectedCrypto(selectedCrypto ? selectedCrypto : res?.crypto_tokens[0]?.id));
      dispatch(setMasterData(res));
    }
  };

  if (isLoading) {
    return (
      <div className="Loader2">
        <Spinner animation="border" />
      </div>
    );
  }

  if (startKYC) return <SubsubKYC />;

  return isAuthenticate ? <Outlet /> : <></>;
};

export default PrivateRoute;
