/* eslint-disable no-negated-condition */
import axios from "axios";
import api_end_point from "../../../constants/api_end_point";

const checkERC20Validation = async (val) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.ERC_20_VALIDATION}`,
    data: val
  });
  return response?.data;
};

export {
  checkERC20Validation,
}