import { kycConstants } from "./kycAction";

const initialState = {
  startKYC: false,
  sumSubAccessToken: "",
  userKYCStatus: "", // kyc_status from api
  isUserKYCDone: false, // status from api
  rejectedType: "" // review_reject_type from api
};


const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case kycConstants.SET_START_KYC:
      return {
        ...state,
        startKYC: action.payload.status
      };
    case kycConstants.SET_SUMSUB_ACCESS_TOKEN:
      return {
        ...state,
        sumSubAccessToken: action.payload.sumSubAccessToken
      };
    case kycConstants.SET_KYC_STATUS:
      return {
        ...state,
        userKYCStatus: action.payload.userKYCStatus,
        isUserKYCDone: action.payload.isUserKYCDone,
        rejectedType: action.payload.rejectedType
      };
    default: return state;
  }
};

export default homeReducer