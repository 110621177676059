import axios from "axios";
import api_end_point from "../../../constants/api_end_point";

const getKYCAccessToken = async () => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.SUMSUB_ACCESS_TOKEN}`,
  });

  return response?.data;
};

const getUserKYCStatus = async () => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.KYC_STATUS}`,
  });

  return response?.data;
};

export {
  getKYCAccessToken,
  getUserKYCStatus,
}