import { logout } from "../pages/home/services/home";

const handleLogout = async ({ natureUserId, natureAccessToken }) => {
  const data = {
    user_id: parseInt(natureUserId, 10),
    access_token: natureAccessToken
  }
  const res = await logout(data);
  if (res?.success) {
    clearLocalStorage();
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}`;
  }
}

const clearLocalStorage = () => {
  localStorage.clear();
};

const getQueryParams = () => {
  const location = window.location.search;
  const params = new URLSearchParams(location);

  const userId = parseInt(params.get('user_id'));
  const accessToken = params.get('access_token');

  return {
    user_id: userId,
    access_token: accessToken
  };
};

const getLastTSPDetail = (data) => {
  const multiTSP = data?.multi_tsp;
  if (multiTSP) {
    const person = [];
    Object.keys(multiTSP).forEach((index) => {
      person.push(multiTSP[index]);
    });
    const totalTSP = person.length - 1;
    return person[totalTSP];
  }
};

const loginRedirection = () => {
  clearLocalStorage();
  if (window.location.pathname === "/") {
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}?redirect_url=${window.location.href}dashboard`;
  } else {
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}?redirect_url=${window.location.href}`;
  }
}

const historyResponseSet = (data) => {
  const responseArr = [];
  if (data) {
    Object.keys(data).forEach(index => {
      const response = {
        project: index,
        ...data[index]
      }
      if (response?.project && response?.purchased_token && response?.invested_amount) {
        responseArr.push(response);
      }
    });
  }

  return responseArr;
}

export {
  clearLocalStorage,
  handleLogout,
  getQueryParams,
  getLastTSPDetail,
  loginRedirection,
  historyResponseSet,
}