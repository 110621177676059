/* eslint-disable nonblock-statement-body-position */
import React, { useState, useEffect } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useDispatch } from "react-redux";
import { getKYCAccessToken } from "./services";
import { setStartKYC } from "./redux/kycAction";

const SubsubKYC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [sumSubAccessToken, setSumSubAccessToken] = useState("");

  const fetchToken = async () => {
    const result = await getKYCAccessToken();
    if (result && result.status)
      setSumSubAccessToken(result.sumsub_access_token);
    setIsLoading(false);
  };

  const expirationHandler = () => {
    alert("Session expired, try again!");
    dispatch(setStartKYC(false));
  };

  const errorHandler = () => {
    alert("Error occurred, try again!");
    dispatch(setStartKYC(false));
  };

  const messageHandler = (message) => {
    console.log(message);

    if (message === "idCheck.onApplicantSubmitted") {
      alert("Documents submitted for KYC");
      dispatch(setStartKYC(false));
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  if (isLoading) return <h3>Loading...</h3>;

  return (
    <SumsubWebSdk accessToken={sumSubAccessToken} expirationHandler={expirationHandler} onMessage={messageHandler} onError={errorHandler} />
  );
};

export default SubsubKYC;
