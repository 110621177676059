export const messageConstant = {
  SET_SELECTED_CRYPTO: "SET_SELECTED_CRYPTO",
  SET_DASHBOARD_DATA: "SET_DASHBOARD_DATA",
  SET_WALLET_DASHBOARD_DATA: "SET_WALLET_DASHBOARD_DATA",
  SET_MASTER_DATA: "SET_MASTER_DATA",
};

const setSelectedCrypto = (values) => {
  return {
    type: messageConstant.SET_SELECTED_CRYPTO,
    payload: {
      selectedCrypto: values,
    },
  };
};

const setDashboardData = (values) => {
  return {
    type: messageConstant.SET_DASHBOARD_DATA,
    payload: {
      dashboard: values.dashboard,
      offSet: values.offSet,
    },
  };
};

const setUserDashboardData = (values) => {
  return {
    type: messageConstant.SET_WALLET_DASHBOARD_DATA,
    payload: {
      userDashboard: values,
    },
  };
};

const setMasterData = (values) => {
  return {
    type: messageConstant.SET_MASTER_DATA,
    payload: {
      masterData: values,
    },
  };
};

export {
  setSelectedCrypto,
  setDashboardData,
  setUserDashboardData,
  setMasterData,
};