import React from "react";

const DashboardBanner = () => {
  return (
    <div className="service-hero">
    </div>
  );
};

export default DashboardBanner;
