/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable multiline-comment-style */
import { _isMetamaskInstalled, _connectToMetamask, _getActiveAccount, _mintToken, _lockToken, _unlockToken } from "./metamaskService";
import walletConnect from "./walletConnect";
// import { _connectWallet } from "../data/wallet_connect";\

// get user connected wallet address
const getUserAddress = async () => {
  const address = await _getActiveAccount();
  return address;
};

// connect user with installed wallet if not connected
const connectWithWallet = async () => {
  const result = await _connectToMetamask();
  return result;
};

// check is metamask wallet installed or not
const checkMetamaskInstallation = async () => {
  const result = await _isMetamaskInstalled();
  return result;
};
// mint token
const mintToken = async (token) => {
  const result = await _mintToken(token);
  return result;
};
const metamaskLockToken = async (data) => {
  const value = {
    id: data.id,
    token: data.token,
    count: data.nonce,
    msg: data.sig,
    address: data.address,
  };
  const lockTokens = await _lockToken(value);
  return lockTokens;
};
const metamaskUnlockToken = async (data) => {
  const value = {
    id: data.id,
    token: data.token,
    count: data.nonce,
    msg: data.sig,
    address: data.address,
  };
  const lockTokens = await _unlockToken(value);
  return lockTokens;
};
const connectMobileWallet = async () => {
  const res = await walletConnect._connectWallet();
  return res;
};
const getTransactionCount = async () => {
  const res = await walletConnect._getTransactionCount();
  return res;
};
const getSignMessage = async (msg) => {
  const res = await walletConnect._getSignMessage(msg);
  return res;
};
const lockToken = async (data) => {
  // const getCount = await getTransactionCount();
  const value = {
    id: data.id,
    token: data.token,
    count: data.nonce,
    msg: data.sig,
    address: data.address,
  };
  const lockTokens = await walletConnect._lockToken(value);
  return lockTokens;
};
const unlockToken = async (data) => {
  const value = {
    id: data.id,
    token: data.token,
    count: data.nonce,
    msg: data.sig,
    address: data.address,
  };
  const unlockTokens = await walletConnect._unlockToken(value);
  return unlockTokens;
};

export default {
  getUserAddress,
  connectWithWallet,
  checkMetamaskInstallation,
  mintToken,
  connectMobileWallet,
  lockToken,
  getTransactionCount,
  getSignMessage,
  unlockToken,
  metamaskLockToken,
  metamaskUnlockToken,
};
