/* eslint-disable no-negated-condition */
import axios from "axios";
import api_end_point from "../../../constants/api_end_point";

const getAboutDetail = async () => {
  const response = await axios({
    method: "Get",
    url: `${api_end_point.BASE_URL}${api_end_point.ABOUT}`,
  });
  return response?.data;
};

export {
  getAboutDetail,
}