// import WalletConnectProvider from "@walletconnect/web3-provider";
const Web3 = require('web3');
const { SmartContractABI } = require("./abi/new-abi-provider");

//  Create WalletConnect Provider
// const provider = new WalletConnectProvider({
//   rpc: {
//     80001: "https://rpc-mumbai.matic.today",
//   },
// });
const provider = new Web3.providers.HttpProvider("https://rpc-mumbai.matic.today")

let account;
const _connectWallet = async () => {
  await provider.enable();
  const web3 = new Web3(provider);
  window.w3 = web3;
  account = await web3.eth.getAccounts();
  return account;
};

const _getTransactionCount = async () => {
  const account = await w3.eth.getAccounts();
  const getCount = await w3.eth.getTransactionCount(account[0]);
  return getCount;
};

const _getSignMessage = async (msg) => {
  const signedMessage = await w3.eth.personal.sign(msg, account[0]);
  return signedMessage;
};

const _getActiveAccount = async () => {
  const accounts = await w3.eth.getAccounts();
  if (accounts.length > 0) {
    return accounts[0];
  }
};

const _lockToken = async (data) => {
  // const tokenNumber = (data.tokenNumber * blockchainToken).toString();
  const stFactory = new w3.eth.Contract(SmartContractABI, data.address);
  console.log("stFactory", stFactory, data, account[0], `${data.count}`);
  const tx = await stFactory.methods
    .lockTokens(data.id, data.token, account[0], `${data.count}`, data.msg)
    .send({ from: account[0] });
  return tx;
};
const _unlockToken = async (data) => {
  const res = await _connectWallet();
  // const tokenNumber = (data.tokenNumber * blockchainToken).toString();

  const stFactory = new w3.eth.Contract(SmartContractABI, data.address);
  console.log("stFactory", stFactory, data, account[0], `${data.count}`, res);
  const tx = await stFactory.methods
    .unlockTokens(data.id, data.token, account[0], `${data.count}`, data.msg)
    .send({ from: account[0] });
  return tx;
};
export default {
  _connectWallet,
  _getTransactionCount,
  _getSignMessage,
  _lockToken,
  _unlockToken,
};
