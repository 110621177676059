import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { homeConstants } from "./homeAction";

const initialState = {
  userDetail: {},
  isLoggedIn: false,
  user: null,
  authToken: null,
  headerList: [],
  smartContract: null,
  isMetamaskInstalled: false,
  isWalletConnected: false,
  walletAddress: null,
};

const persistConfig = {
  storage,
  key: "sunset-greenH2-home",
  whitelist: ["userDetail"],
  transforms: [
    encryptTransform({
      secretKey: 'sunset-greenH2-persist-secure',
      onError: function (error) {
        // Handle the error.
      }
    })
  ]
};

const homeReducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case homeConstants.SET_USER_DETAIL:
      return {
        ...state,
        userDetail: action.payload,
      };
    case homeConstants.SET_SMART_CONTRACT:
      return {
        ...state,
        smartContract: action.payload.smartContract,
      };
    case homeConstants.SET_METAMASK_INSTALLED_STATUS:
      return {
        ...state,
        isMetamaskInstalled: action.payload.status,
      };
    case homeConstants.SET_IS_WALLET_CONNECTED:
      return {
        ...state,
        isWalletConnected: action.payload.status,
      };
    case homeConstants.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload.status,
      };
    case homeConstants.SET_WALLET_ADDRESS:
      return {
        ...state,
        walletAddress: action.payload.walletAddress,
      };
    default:
      return state;
  }
});

export default homeReducer