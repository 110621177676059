import { MESSAGE_VARIANT } from "../../../app/constants/constants";

export const messageConstant = {
  SET_SUCCESS_MESSAGE: "SET_SUCCESS_MESSAGE",
  SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE"
};

const setSuccessMessage = (message) => {
  return {
    type: messageConstant.SET_SUCCESS_MESSAGE,
    payload: {
      message,
      messageType: MESSAGE_VARIANT.SUCCESS
    }
  };
};

const setErrorMessage = (message) => {
  return {
    type: messageConstant.SET_ERROR_MESSAGE,
    payload: {
      message,
      messageType: MESSAGE_VARIANT.ERROR
    }
  };
};

export {
  setSuccessMessage,
  setErrorMessage
};