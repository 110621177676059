import React from "react";
import OtherNFTProjects from "./otherNftProjects";
import GraphTableTabs from "./graphTableButton";

const DashboardHeading = ({ headingType = "dashboard", tabSwitcher = "yes" }) => {
  return (
    <div className="service-title-bar mb-4 mb-lg-5">
      <h2 style={{ color: "black" }}>Buy Sunset GreenH2 NFT</h2>
      <OtherNFTProjects />
      {tabSwitcher === "yes" ? (
        <div className="db-panel me-auto ms-auto me-md-0 ms-md-3 my-2 my-md-0">
          <GraphTableTabs />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DashboardHeading;
