export const alertConstant = {
  SET_ALERT_DATA: "SET_ALERT_DATA"
}


const setShowAlertData = (values) => {
  return {
    type: alertConstant.SET_ALERT_DATA,
    payload: {
      alertType: values.alertType,
      alertTitle: values.alertTitle,
      alertMessage: values.alertMessage,
      status: true
    }
  };
};

const setCloseAlertData = () => {
  return {
    type: alertConstant.SET_ALERT_DATA,
    payload: {
      alertType: "",
      alertTitle: "",
      alertMessage: "",
      status: false
    }
  };
};

export { setShowAlertData, setCloseAlertData };