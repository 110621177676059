import { combineReducers } from "redux";
import messageReducer from "./commonRedux/messageRedux/messageReducer";
import alertReducer from "../components/toast/redux/alertReducer";
import nftReducer from "../app/pages/nft/redux/nftReducer";
import homeReducer from "../app/pages/home/redux/homeReducer";
import paginationReducer from "../app/pages/pagination/redux/paginationReducer";
import kycReducer from "../app/pages/kyc/redux/kycReducer";

export const rootReducer = combineReducers({
  message: messageReducer,
  alert: alertReducer,
  nft: nftReducer,
  home: homeReducer,
  pagination: paginationReducer,
  kyc: kycReducer,
});
